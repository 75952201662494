import React from 'react'
import { GetAds } from '../../utils/common';
import Comments from './Comments';
import { AdComponent, AuthorDetail, ListComment } from './Custom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import arrowright from '../../assets/images/icons/white/arrow-right-circle.svg'
function UnlockedContent({content,mainArticles,authorDetail,comments}) {
    var imag=1
    var count =0
    var ads=0
  return (
      <>
    {content?.map((item,index)=>{
        if(count<4){
           count+=1
       return(
       <div key={index} style={{padding: '7px 0'}} className="entry">
        <div className='single__content' dangerouslySetInnerHTML={{ __html: item }}></div>
        </div>
)}else{

count=0
ads+=1
return(
<div key={index}><Swiper
      // install Swiper modules
      modules={[Navigation,Pagination,  Scrollbar, A11y]}
      spaceBetween={5}
      slidesPerView={1.37}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log('swiper')}
      onSlideChange={() => console.log('slide change')}
    > 
{Object.keys(mainArticles).length !== 0?mainArticles.images.map((i,index)=>{
   if(imag===index){
       imag+=1
   return(
<>
<SwiperSlide> <div key={index} className="swiper-slide slider-cover__slide slider-cover__slide--34" 
style={{ backgroundImage:`url(${i.url})`, opacity: '0.8'}}>
       </div><div className="top-image-caption">
           <small>
               {i?.caption}
           </small></div></SwiperSlide>
           </>
   )
   }
}):''}
</Swiper>
<GetAds ads={ads}/>

  </div>
)



}
})}
<AuthorDetail authorDetail={authorDetail}/>
{mainArticles.moreLink&&(<p style={{margin: "0 20px",color: "#000",fontSize: "17px",
fontFamily: "Merriweather, serif",
fontWeight: "bold", letterSpacing: ".51px",display: "flex"}} class="adf_post_body">
						
						<b>You can read the full story</b>
             <a href={mainArticles.moreLink} class="label bg-default" 
             style={{backgroundColor: "#891a1a",borderRadius: "6px",
             marginLeft: "0.41rem",
             fontWeight: "bold",color: "rgb(75, 131, 180)",
             display: "flex", alignItems: "center"}}> 
             <span><b style={{color: "white"}}> &nbsp;&nbsp;&nbsp;here &nbsp;&nbsp;&nbsp;</b></span>
             <img style={{    width: '30px',
    height: '20px',
    filter: 'brightness(0.5)'}} src={arrowright} alt=''/></a>
					
					</p>)}
{Object.keys(mainArticles).length !== 0&&(<><img className = 'adv__image'
            src = 'https://addisfortune.news/wp-content/uploads/2022/08/telebirr-finance_600x90-Fortune-Eng-Front-Top.jpg'
            alt = '' />
            <hr style={{marginBlockStart: "-0.6em",border: "0.1px solid rgb(227 227 227)",
            borderWidth: "0.1px",width: "95%"}} />
          <ListComment comments={comments}/>
<Comments id={mainArticles.sub_id}/>
<AdComponent slot="9425480604"/></>)}

</>
  )
}

export default UnlockedContent