import React, { useEffect, useState } from 'react'
import { db,check_login } from '../../Firebase/FIREBASE_CONFIG'
import SingleHeader from './SingleHeader'
import { useParams } from 'react-router-dom'
import RightPanel from '../../component/RightPanel'
import LockedContent from './LockedContent'
import UnlockedContent from './UnlockedContent'
import { useAddisContext } from '../../context/AddisContext'
import Gallery from './Gallery'
import defaultImage from '../../assets/images/photos/defaultImage.jpg'
import { getAuthorDetail, getComment } from '../../utils/common'
import YearInReview from './YearInReview'
function Single() {
  const {category, title } = useParams()
  const urlLink=`/${category}/${title}`
  const {
    setActiveState,
    user,
    rightPanel,
    hideNews,
    setRightPanel,
    setIsRightPanelOpen,
    setHideNews
} = useAddisContext()
  const [content, setContent]=useState([])
  const [authorDetail, setAuthorDetail]=useState([])
  const [singleSlug, setSingleSlug] = useState([category,title]);
  const [shareClass,setShareClass]=useState('share_file')
  const [comments,setComments]=useState([])
  const [mainArticles, setMainArticles] =useState({})
  const openShareUrl=()=>{
    if(shareClass==='share_file'){
      setShareClass('share_file show__share__link')
    }else{
      setShareClass('share_file')
    }
  }
    const getArticles=()=>{
      check_login()
        const splitTitile=title.split('_')
        const id=splitTitile[1]
        db.collection('articles')
        .doc(id)
        .get()
        .then(querySnapshot => {
        //    console.log(querySnapshot.data())
        //console.log(querySnapshot.data().content.split('</strong>')[0])
            setContent(querySnapshot.data().content.replaceAll('<p>','').split('</p>'))
           getAuthorDetail(querySnapshot.data().authorIds[0]?.toString()).then(res => res).then(resource=>
              {
               if(resource.data()){
                const auDetail={
                  authorName:resource.data()?.name,
                  authorDesc:resource.data()?.description.replaceAll('<p>','').split('</p>')
                }
                setAuthorDetail(auDetail)
              }
              })
          const updatedArticleItems = {
            sub_id:querySnapshot.id,
            title: querySnapshot.data().title,
            authors:querySnapshot.data().authors,
            authorIds:querySnapshot.data().authorIds,
            content:querySnapshot.data().content,
            slug:"https://addisfortune.news/"+querySnapshot.data().slug,
            excerpt:querySnapshot.data().excerpt.replaceAll('<p>','').split('</p>'),
            locked:user?false:querySnapshot.data().locked,
            images:querySnapshot.data().images,
            top:querySnapshot.data().images[0]?.url,
            moreLink:querySnapshot.data().moreLink,
            caption:querySnapshot.data().images[0]?.caption,
            mainNews: querySnapshot.data().mainNews?.toString(),
            created: querySnapshot.data().created?.toDate().toDateString()}
                        setMainArticles(updatedArticleItems)
         
        });
        
        
      }
      const getComments=()=>{
        const splitTitile=title.split('_')
        const id=splitTitile[1]
        getComment(id).then(querySnapshot=>{
          const latestComments=querySnapshot.docs
          latestComments.map((js,index)=>{
            const newSho = {
              sub_id:js.id,
              fullname: js.data().fullname,
              message: js.data().message,
              created: js.data().created?.toDate().toDateString()
            }
            setComments(comments => [...comments, newSho]);
          })
        })
      }
    useEffect(() => {
        setSingleSlug([category,title])
        setMainArticles([])
        setRightPanel("panel panel--right")
        setHideNews('page page--main')
        setIsRightPanelOpen(false)
     
      getArticles()
      getComments()
      setActiveState(category)
    },[user])
    //console.log(headerArticles)
    //console.log(authorDetail)
   
    
  return (
    <div style={{ height: "100vh", overflow: "auto",}} className="App">
    <div className="body-overlay"></div>

<div id="panel-left"></div>

<RightPanel rightPanel={rightPanel}/>
<div className={hideNews} data-page="blog">YearInReview
<div style={{paddingTop: '50px',paddingBottom:'5%'}} className="page__content">
  
    <SingleHeader category={category} openShareUrl={openShareUrl} shareClass={shareClass} newsUrl={mainArticles.slug} title={mainArticles.title}/>
    {category==='gallery'&&mainArticles&&(<Gallery mainArticles={mainArticles} content={content}/>)}
    {category==='year-in-review'&&mainArticles&&(<YearInReview mainArticles={mainArticles} content={content}/>)}
    <>{!(category==='gallery'||category==='year-in-review')&&(<div>
           {Object.keys(mainArticles).length !== 0&&(<div className="image-top"> 
                <img src={mainArticles&&mainArticles?.top?mainArticles.top:defaultImage} alt="" title="" />

                <div className="image-top__details">
                    <h1 style={{paddingLeft:'1.5rem'}}   >
                      <span dangerouslySetInnerHTML={{ __html: mainArticles?.title }}></span></h1>

                </div>
                
            </div>)}
            <div className="top-image-caption">
                    <small>
					{mainArticles?.caption}
				</small></div>
            {mainArticles.authors && (<div className="entry single-author" style={{color:'black',textAlign: 'left',margin: '0 20px'}}>
               <div className='author__list'>
               <span style={{background:'#fef8e6',fontStyle: 'italic',paddingTop:'10px',paddingBottom:'0 !important'}} className=" d-block ">{mainArticles.authors.length>0?'By':''} {mainArticles.authors?.map(item=>(
                   item+', '
                ))}</span>
                <span  style={{background:'#fef8e6',fontStyle: 'italic',paddingTop:'0px'}} className=" d-block pb-10">{mainArticles?.created}</span>
         
               </div>
              </div>)}

            <div className="entry">
                <div className="blurb">
                    <p className='single__excerpt'  dangerouslySetInnerHTML={{ __html: mainArticles.excerpt }}>
                    </p>
                </div>
            </div>
            {mainArticles?.locked?(<LockedContent urlLink={urlLink} content={content}/>):(
            <UnlockedContent content={content} mainArticles={mainArticles} 
            comments={comments} authorDetail={authorDetail}/>)}
        
            </div>)}</>

</div>
    </div>
    <div style={{marginBottom:'4rem'}}>
    </div>
    </div>
  )
}

export default Single