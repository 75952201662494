import React from 'react'
import Comments from './Comments'
import { AdComponent } from './Custom'

function YearInReview({mainArticles, content}) {
    var imag=1
    var count =-1
    var ads=-1
  return (
    <div>
        <div className="entry" style={{color:'black',textAlign: 'center',marginTop: '10px',marginBottom:' -30px !important'}}>
		<h1 className="category-top-title" 
    style={{marginBottom: '0px',fontSize:'33px',
    textAlign: 'left',
    marginLeft: '15px'}} id="single_news_title">{mainArticles?.title}</h1>
	</div>
    {mainArticles.excerpt&&(<div className="entry">
                <div className="blurb">
                    <p className='single__excerpt'>
                       {mainArticles.excerpt}
                    </p>
                </div>
            </div>)}
      {content?.map((item,index)=>{
        if(item.includes('</strong>')){
            if(!item.includes('</em>'))ads=parseInt(ads+1)
            return (<>
                
                  <div className={!item.includes('</em>')?'single__content ': 'single__content strong_span'}  dangerouslySetInnerHTML={{ __html: item }}></div>
                  
                 {!item.includes('</em>')&&( <div className="image-top">
                    <img style={{objectFit: "cover", width: "100%"}} src={mainArticles?.images?mainArticles?.images[ads]?.url:''} alt="" title="" />
                  </div>)}
                  </>)
                 
        }else{
       return(
       <div key={index} style={{padding: '7px 0'}} className="entry">
        <div className='single__content' dangerouslySetInnerHTML={{ __html: item }}></div>
        </div>
)}
      })}
      {Object.keys(mainArticles).length !== 0&&(<>
        <img className = 'adv__image'
            src = 'https://addisfortune.news/wp-content/uploads/2022/08/telebirr-finance_600x90-Fortune-Eng-Front-Top.jpg'
            alt = '' />
            <hr style={{marginBlockStart: "-0.6em",border: "0.1px solid rgb(227 227 227)",
            borderWidth: "0.1px",width: "95%"}} />
            <Comments id={mainArticles.sub_id}/>
      <AdComponent slot="9425480604"/></>)}
    </div>
  )
}

export default YearInReview
