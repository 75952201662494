
import './assets/css/style.css';
import './assets/css/skeleton.css'
import Home from './pages/Home/Home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Category from './pages/Category/Category';
import Single from './pages/Single/Single';
import Search from './component/Search';
import Verbatim from './pages/Category/Verbatim';
import { check_login } from './Firebase/FIREBASE_CONFIG';
import React from 'react';
import { AddisContextProvider } from './context/AddisContext';
import Login from './pages/Login/Login';

function App() {
  check_login()
  return (
    <BrowserRouter>
    <AddisContextProvider>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/:category/:title" element={<Single />}/>
      <Route path="/category/:name" element={<Category />}/>
      <Route path="/verbatim" element={<Verbatim />}/>
      <Route path="/login" element={<Login />}/>
      <Route path="/search/:result" element={<Search />}>
      </Route>
    </Routes>
    </AddisContextProvider>
  </BrowserRouter>
  );
}

export default App;
