import React, { useState } from 'react'
import { useEffect } from 'react'
import { addnewComment, validatEmail } from '../../utils/common'
import firebase from 'firebase/compat/app';
function Comments({id}) {
const [loading,setLoading]=useState(false)
const [successComment,setSuccessComment]=useState(false)
const [fullname,setFullname]=useState()
const [fullname__error,setFullnameError]=useState()
const [email,setEmail]=useState()
const [email__error,setEmailError]=useState()
const [message,setMessage]=useState()
const [message__error,setMessageError]=useState()
const [hiddenButton,onHiddenButton]=useState("form__submit button button--main button--full hidden_button")
 const fullNameBlur=e=>{
    e?.preventDefault();
        if(!fullname){
            onHiddenButton("form__submit button button--main button--full hidden_button")
            setFullnameError("Fullname can't be empty")
        }else{
            if(message?.length>=15&&validatEmail(email))onHiddenButton("form__submit button button--main button--full")
            setFullnameError()
        }
 }
 const fullNameKeyUp=e=>{
    e?.preventDefault();
        if(fullname){
            if(message?.length>=15&&validatEmail(email)){
                onHiddenButton("form__submit button button--main button--full")
            }else{
                    onHiddenButton("form__submit button button--main button--full hidden_button")
                }
            setFullnameError()
        }
 }
 const emailBlur=e=>{
    e?.preventDefault();
    console.log(validatEmail(email))
        if(!email||!validatEmail(email)){
            setEmailError("Invalid Email format")
            onHiddenButton("form__submit button button--main button--full hidden_button")
        }else{
            
            if(message?.length>=15&&fullname&&validatEmail(email))onHiddenButton("form__submit button button--main button--full")
            setEmailError()
        }
 }
 const emailKeyUp=e=>{
    e?.preventDefault();
        if(email){
            if(message?.length>=15&&fullname&&validatEmail(email)){
                onHiddenButton("form__submit button button--main button--full")
            }else{
                    onHiddenButton("form__submit button button--main button--full hidden_button")
                }
            setEmailError()
        }
 }
 const messageBlur=e=>{
    e?.preventDefault();
        if(!message||message?.length<15){
            setMessageError("Comment must be greater than 15 charactors")
            onHiddenButton("form__submit button button--main button--full hidden_button")
        }else{
            if(fullname&&validatEmail(email))onHiddenButton("form__submit button button--main button--full")
            setMessageError()
        }
 }
 const messageKeyUp=e=>{
    e?.preventDefault();
        if(message){
            if(message.length>=15&&fullname&&validatEmail(email)){
                onHiddenButton("form__submit button button--main button--full")
            }else{
                onHiddenButton("form__submit button button--main button--full hidden_button")
            }
            setMessageError()
        }
 }
 const validateForm=e=>{
    e?.preventDefault()
    setLoading(true)
    if(message.length>=15&&fullname&&validatEmail(email)){
       const createdD=firebase.firestore.Timestamp.fromDate(new Date())
        const newdata = {
            postId:id,
            fullname: fullname,
            email: email,
            message: message,
            visible: false,
            created:createdD
          }
        addnewComment(newdata)
        setTimeout(() => {  setLoading(false)
            setSuccessComment(true)
        },2000);
    setTimeout(() => {
        setFullname()
        setEmail()
        setMessage()
        setSuccessComment(false)
}, 4000);
    }else{
        fullNameBlur()
        emailBlur()
        messageBlur()
        setLoading(false)
    }
 }
  return (
    <div>
      <div id="comments" className="comments-area" 
      style={{marginTop:"10px",margin:" 0 20px",textAlign: "justify",color: "#181616",
      background: '#fff0db',
    padding: '12px',
      fontSize: "1.6rem"}}>

		

<h2 style={{fontFamily: "Roboto, sans-serif",
    fontWeight: "500"}} className="page__title">Put your comments here</h2>
{successComment?<h2>Thank You for submiting your comment</h2>:(
<><small style={{fontSize: "13px",fontFamily: "Roboto, sans-serif"}}>NB: A submit button will appear once you fill out all the required fields.</small>
<div style={{marginTop:'1rem'}} className="row"> 
<div className="form__row">
    <input 
    onBlur={fullNameBlur}
    onKeyUp={fullNameKeyUp} value={fullname} onChange={(e)=>setFullname(e.target.value)} type="text" id="full_name" name="full_name" placeholder="Full name - Required" className="form__input required" required=""/>
   <small style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{fullname__error}</small>
</div>

<div className="form__row">
    <input  
    onBlur={emailBlur}
    onKeyUp={emailKeyUp} value={email} type="email" onChange={(e)=>setEmail(e.target.value)} id="user_email" name="user_email" placeholder="Email address" className="form__input required" required=""/>
     <small style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{email__error}</small>
</div>
<div className="form__row">
    <textarea  
    onBlur={messageBlur}
    onKeyUp={messageKeyUp}
    onChange={(e)=>setMessage(e.target.value)} name="user_comment" id="user_comment" className="form__textarea required" rows="3" required="" placeholder="Comment ( Comment must be greater than 15 charactors)  - Required" spellCheck="false">
        {message}
    </textarea>
    <small style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{message__error}</small><br/>
    <label id="count">Charactor count: {message?.length} Minimum allowed: 15</label>
</div>

    <div className="col-lg-12 col-md-12">
        <br/>
        <button style={{display: "flex",justifyContent: "center"}} onClick={validateForm} disabled={loading} className={hiddenButton}>
        {loading && (
        <i
          className="loading-spinner "
          style={{ marginRight: "5px" }}
        />
      )}
      {loading && <span>Submiting</span>}
      {!loading && <span>Submit Your comment</span>}
   </button>
    </div>
    <div style={{marginBottom: "5rem"}}> 
        <label id="capcha_error" className="text-danger"></label>
    </div>
    </div></>
)}






</div>
</div>
  )
}

export default Comments
