import React from 'react'
import logo from '../assets/images/photos/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { useAddisContext } from '../context/AddisContext'
function LeftPanel() {
    const navigate=useNavigate()
    const {activeState,user,setUser,leftPanel,
        setLeftPanel,setHideNews,setMenuIcon,setIsPanelOpen}=useAddisContext()
    const logoutUser=()=>{
      localStorage.removeItem('subscriberUsername')
      setUser('')
      setLeftPanel("panel panel--left")
      setHideNews('page page--main')
      setMenuIcon('header__icon header__icon--menu open-panel')
      setIsPanelOpen(false)
      navigate(`/`)

    }
      return (
    <div className={leftPanel}>
        <div className="panel__navigation">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <div className="user-details">
                        <div className="user-details__thumb"><img src={logo} alt="" title="" /></div>
                        {user?(<div style={{width:'100%'}} className="name-and-logout">
								<div className="user-details__title">
							<span>Welcome Back <b>   </b> </span>
							<span style={{marginLeft: "auto",marginRight: "10px"}}> 
                            <a href="https://addisfortune.news/subscription/login.php"> Edit Profile </a> </span>
                            <div onClick={logoutUser} className="user_logout" style={{borderLeft: "1px solid black"}}> Logout </div>
                            </div>
						    <hr style={{height: "1px",backgroundColor: "#f0eaea",margin:'0', border: "none",width: "100%"}}/></div>):(
                            <div className="user-details__title">
                            <Link to={'/login'} className='user_logout'>Login</Link></div>)}
                        
                            
                    </div>
                    <nav className="main-nav">
                        <ul>
                            <li className={activeState==='home'?'adf_menu_active':''}>
                                <Link to={'/'}><span>Home</span></Link>
                            </li>
                            <li className={activeState==='fortune-news'?'adf_menu_active':''}>
                            <Link to={`/category/fortune-news`} ><span>Fortune News</span></Link>
                            </li>
                            <li className={activeState==='agenda'?'adf_menu_active':''}>
                            <Link to={'/category/agenda'} ><span>Agenda</span></Link>
                            </li>
                            <li className={activeState==='editorials'?'adf_menu_active':''}>
                            <Link to={'/category/editorials'}><span>Editorial</span></Link>
                            </li>
                            <li className={activeState==='fineline'?'adf_menu_active':''}>
                            <Link to={'/category/fineline'}><span>Fineline</span></Link>
                            </li>
                            <li className={activeState==='news-analysis'?'adf_menu_active':''}>
                            <Link to={'/category/news-analysis'}><span>News Analysis</span></Link>
                            </li>
                            <li className={activeState==='feature-news'?'adf_menu_active':''}>
                            <Link to={'/category/feature-news'}><span>Feature</span></Link></li>
                            <li className={activeState==='interview'?'adf_menu_active':''}>
                            <Link to={'/category/interview'}><span>Interview</span></Link></li>
                            <li className={activeState==='commentaries'?'adf_menu_active':''}>
                            <Link to={'/category/commentaries'}><span>Commentary</span></Link></li>
                            <li className={activeState==='viewpoint'?'adf_menu_active':''}>
                            <Link to={'/category/viewpoint'}><span>Viewpoint</span></Link></li>
                            <li className={activeState==='opinion'?'adf_menu_active':''}>
                            <Link to={'/category/opinion'}><span>Opinion</span></Link></li>
                            <li className={activeState==='view-from-arada'?'adf_menu_active':''}>
                            <Link to={'/category/view-from-arada'}><span>View From Arada</span></Link></li>
                            <li className={activeState==='radar'?'adf_menu_active':''}>
                            <Link to={'/category/radar'}><span>Radar</span></Link></li>
                            <li className={activeState==='verbatim'?'adf_menu_active':''}>
                            <Link to={'/category/verbatim'}><span>Verbatim</span></Link></li>
                            <li className={activeState==='news-alert'?'adf_menu_active':''}>
                            <Link to={'/category/news-alert'}><span>News Alert</span></Link></li>
                            <li className={activeState==='breaking-news'?'adf_menu_active':''}>
                            <Link to={'/category/breaking-news'}><span>Breaking News</span></Link></li>
                            <li className={activeState==='gallery'?'adf_menu_active':''}>
                            <Link to={'/category/gallery'}><span>Gallery</span></Link></li>
                            <li className={activeState==='year-in-review'?'adf_menu_active':''}>
                            <Link to={'/category/year-in-review'}><span>Year in review</span></Link></li>
                            <li className={activeState==='digital-first'?'adf_menu_active':''}>
                            <Link to={'/category/digital-first'}><span>Digital First</span></Link></li>
                            <li className={activeState==='election-2021-coverage'?'adf_menu_active':''}>
                            <Link to={'/category/election-2021-coverage'}><span>Election 2021 Coverage</span></Link></li>
                            <li className={activeState==='covid-19'?'adf_menu_active':''}>
                            <a href='https://soundcloud.com/user-965523810' target='_blank' rel="noreferrer"><span>Fortune Audio</span></a></li>
                            <li className={activeState==='covid-19'?'adf_menu_active':''}>
                            <Link to={'/category/covid-19'}><span>COVID-19 Updates</span></Link></li>
                            <li className={activeState==='international-stories'?'adf_menu_active':''}>
                            <Link to={'/category/international-stories'}><span>International Stories</span></Link></li>
                            <li className="subnav opensubnav">
                            <a href='https://www.video.addisfortune.net/' target='_blank' rel="noreferrer"><span>Fortune Video</span></a></li>
                            <li className="subnav opensubnav">
                            <a href='https://tradeafrica.addisfortune.info/' target='_blank' rel="noreferrer"><span>Trade Africa</span></a></li>
                            <li className="subnav opensubnav">
                            <a href='https://printversion.addisfortune.net/' target='_blank' rel="noreferrer"><span>Print Version</span></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LeftPanel