import React from 'react'
import share from '../../assets/images/icons/black/share.svg'
import close from '../../assets/images/icons/black/close.svg'
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    WeiboShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";
  function Share({openShareUrl,shareClass,newsUrl,title}) {
  return (
    <>
    <div className="header__icon open-panel"  onClick={openShareUrl} style={{filter: "invert(1)"}} data-panel="right">
          <img src={shareClass==='share_file'?share:close} alt="" title="" /></div>
   <div className={shareClass}>
   <div  className='share__dropdown'>
   <EmailShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <EmailIcon size={36} />
              </EmailShareButton>
              <FacebookShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <FacebookIcon size={36} />
              </FacebookShareButton>
              <FacebookMessengerShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <FacebookMessengerIcon size={36} />
              </FacebookMessengerShareButton>
              <HatenaShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <HatenaIcon size={36} />
              </HatenaShareButton>
              <InstapaperShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <InstapaperIcon size={36} />
              </InstapaperShareButton>
              <LineShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <LineIcon size={36} />
              </LineShareButton>
              <LinkedinShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <LinkedinIcon size={36} />
              </LinkedinShareButton>
              <LivejournalShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <LivejournalIcon size={36} />
              </LivejournalShareButton>
              <MailruShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <MailruIcon size={36} />
              </MailruShareButton>
              <OKShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <OKIcon size={36} />
              </OKShareButton>
              <PinterestShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <PinterestIcon size={36} />
              </PinterestShareButton>
              <PocketShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <PocketIcon size={36} />
              </PocketShareButton>
              <RedditShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <RedditIcon size={36} />
              </RedditShareButton>
              <TelegramShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <TelegramIcon size={36} />
              </TelegramShareButton>
              <TumblrShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <TumblrIcon size={36} />
              </TumblrShareButton>
              <TwitterShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <TwitterIcon size={36} />
              </TwitterShareButton>

              <ViberShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <ViberIcon size={36} />
              </ViberShareButton>
              <VKShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <VKIcon size={36} />
              </VKShareButton>
              <WhatsappShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <WhatsappIcon size={36} />
              </WhatsappShareButton>
              <WorkplaceShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <WorkplaceIcon size={36} />
              </WorkplaceShareButton>
              <WeiboShareButton 
                url={newsUrl}
                quote={"CampersTribe - World is yours to explore"}
                
                style={{height: "40px !important",
                width: "40px !important",}}>
                 <WeiboIcon size={36} />
              </WeiboShareButton>
              
   </div></div></>
  )
}

export default Share
