import React from 'react'

function VerbatimCard({topArticles}) {
  return (
    <div style={{display: 'flex',justifyContent: 'space-around',alignItems:'center'}} className="image-top">
                <img style={{width: '60%'}} src={topArticles[0]?.images} alt="" title="" />

                <div style={{position:'unset'}} className="image-top__details">
                    <h1 style={{paddingLeft:'1.5rem'}} >
                      <span>{topArticles[0]?.title}
                      </span></h1>

                </div>

            </div>
  )
}

export default VerbatimCard