import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { check_login } from '../../Firebase/FIREBASE_CONFIG'
import TopHeader from '../../component/Header'
import LeftPanel from '../../component/LeftPanel'
import { articleRef, getCategoryId, getCategoryName } from '../../utils/common';
import Card from './Card'
import CategoryList from './CategoryList'
import RightPanel from '../../component/RightPanel';
import CategorySkeleton from '../../skeleton/CategorySkeleton';
import { useAddisContext } from '../../context/AddisContext';
function Category() {
  const { name } = useParams()
  const {
    setActiveState,
    hideNews,
    setHideNews,
    setLeftPanel,
    setMenuIcon,
    setIsPanelOpen}=useAddisContext()
  const [lastKey, setLastKey] = useState();
  const [loading, setLoading] = useState(false);
  const [topArticles, setTopArticles] = useState([])
  const loadMoredata=()=>{
    setLoading(true)
    var element=getCategoryId(name)
    articleRef(element).startAfter(lastKey).limit(10).get().then((collections)=>{
      getLatestArticleWithCategory(collections);
    });
  }
  const getLatestArticleWithCategory=(categoryLatestArticleSnapshot,element)=>{
    const latestCategoryArticle=categoryLatestArticleSnapshot.docs
    const Lastdoc = latestCategoryArticle[latestCategoryArticle.length-1];
    setLastKey(Lastdoc);
    latestCategoryArticle?.map((js)=>{
      const newSlug=js.data().slug+"_"+js.id
      const catSlug=getCategoryName(element)
      const newSho = {
        sub_id:js.id,
        title: js.data().title,
        slug:newSlug,
        catSlug:catSlug,
        authors: js.data().authors[0],
        categories: js.data().categories[0],
        excerpt: js.excerpt ? js.data().excerpt.slice(0, 150) + "..." : js.data().content.slice(0, 150) + '...',
        created: js.data().created?.toDate().toDateString(),
        images:js.data().images[0]?js.data().images[0].url:js.data().images[1]?.url
      }
      
      setTopArticles(topArticles => [...topArticles, newSho]);
      setLoading(false)
     
    })
}

  useEffect(() => {
    check_login()
    setActiveState(name)
    var element=getCategoryId(name)
      setTopArticles([])
      setLeftPanel("panel panel--left")
      setHideNews('page page--main')
      setMenuIcon('header__icon header__icon--menu open-panel')
      setIsPanelOpen(false)
    articleRef(element).limit(20).get().then((collections)=>{
      getLatestArticleWithCategory(collections,element)
     
    });
  },[name])
   return (
    //this will help to scroll the page
    <div style={{ height: "100vh", overflow: "auto" }} className="App">
       <div className="body-overlay"></div>
  
       <LeftPanel/>
       <RightPanel/>
  <div id="panel-right"></div>
  <div className={hideNews} data-page="blog">
     <TopHeader/> 
     {topArticles.length?(<div style={{paddingTop: '59px'}} className="page__content page__content--with-header"> 
     <Card topArticles={topArticles}/>
           <div style={{    marginTop: '3rem'}} className="page__title-bar">
            </div>
           <CategoryList topArticles={topArticles} loadMoredata={loadMoredata} loading={loading}/>
            </div>):(<div style={{paddingTop: '59px'}} className="page__content page__content--with-header">
              <CategorySkeleton/>
            </div>)}
     
        </div>
    </div>
  )
}

export default Category