import React from 'react'
import arrowBack from '../../assets/images/icons/white/arrow-back.svg'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/icons/adf_logo-inverse.png'
import Share from './Share'

function SingleHeader({category,openShareUrl,shareClass,newsUrl,title}) {
  const navigate=useNavigate()
  const backArrow=()=>{
    navigate(`/category/${category}`)
  }
  return (
    <header  className='header header--fixed header--change header--page'>
    <div className="header__inner">
        <div onClick={backArrow} className="header__icon">
           <img src={arrowBack} alt="" title="" />
        </div>
        <div className="header__logo header__logo--text"><a href="#h">
          <img style={{opacity: "1", height: "30px"}} src={logo} alt=""/></a></div>
        <Share openShareUrl={openShareUrl} shareClass={shareClass} newsUrl={newsUrl} title={title}/>
    </div>
</header>

  )
}

export default SingleHeader