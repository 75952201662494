import React from 'react';
import Header from '../../component/Header';
import SliderThumbs from './SliderThumbs';
import ListNews from '../../component/ListNews';
import LeftPanel from '../../component/LeftPanel'
import { useEffect, useState } from 'react';
import { db,check_login } from '../../Firebase/FIREBASE_CONFIG'
import RightPanel from '../../component/RightPanel';
import { adf_number,  CheckNewsAlertBreaking,  getCategoryName, getPriority, isSaturday } from '../../utils/common';
import Skeleton from '../../skeleton/Skeleton';
import { useAddisContext } from '../../context/AddisContext';
function Home() {
 // check_login()
 const {setActiveState,hideNews,setHideNews,
  setLeftPanel,setMenuIcon,setIsPanelOpen}=useAddisContext()
 const [counter,setCounter]=useState(1)
  const [loading, setLoading] = useState(false);
  const [topArticles, setTopArticles] = useState([])
  const [nextArticle, setNextArticle] = useState([])
  const [headerArticles,setHeaderArticles] = useState([])
  const loadMoredata=()=>{
    setLoading(true)
    getLatestArticleWithCategory()
    setCounter(counter+1)
  }
   const getLatestArticleWithCategory=async()=>{
    check_login()
    try{
      const element=adf_number()
     // console.log(
      const categoryLatestArticleSnapshot = await db.collection("articles")
          .where("number", "==", element-counter-(isSaturday()?1:0))
          .orderBy("top", "desc")
          .orderBy("created", "desc")
          .get()
      const latestCategoryArticle=categoryLatestArticleSnapshot.docs
      var countNews=0
      CheckNewsAlertBreaking(-2)&&CheckNewsAlertBreaking(-2).then(response=>{
        const brnews=response.docs
        brnews.map((js,index)=>{
          const newSlug=js.data().slug+"_"+js.id
          const catSlug=getCategoryName(js.data().categoryIds[0])
          const newSho = {
            sub_id:js.id,
            slug:newSlug,
            catSlug:catSlug,
            categories: js.data().categories[0],
            cateId: js.data().categoryIds[0],
            images:js.data().images[0]?.url
          }
          setHeaderArticles(headerArticles => [...headerArticles, newSho]);
        })
        
      })
      CheckNewsAlertBreaking(-1)&&CheckNewsAlertBreaking(-2).then(response=>{
        const brnews=response.docs
        brnews.map((js,index)=>{
          const newSlug=js.data().slug+"_"+js.id
          const catSlug=getCategoryName(js.data().categoryIds[0])
          const newSho = {
            sub_id:js.id,
            slug:newSlug,
            catSlug:catSlug,
            categories: js.data().categories[0],
            cateId: js.data().categoryIds[0],
            images:js.data().images[0]?.url
          }
          setHeaderArticles(headerArticles => [...headerArticles, newSho]);
        })
        
      })
      latestCategoryArticle.map((js,index)=>{
        const newSlug=js.data().slug+"_"+js.id
        const catSlug=getCategoryName(js.data().categoryIds[0])      
        const newSho = {
          sub_id:js.id,
          title: js.data().title,
          slug:newSlug,
          catSlug:catSlug,
          authors: js.data().authors[0],
          categories: js.data().categories[0],
          cateId: js.data().categoryIds[0],
          priortiy:getPriority(js.data().categoryIds[0]),
          excerpt: js.excerpt ? js.data().excerpt.slice(0, 150) + "..." : js.data().content.slice(0, 150) + '...',
          created: js.data().created?.toDate().toDateString(),
          images:js.data().images[0]?.url
        }
        if(!((js.data().categoryIds[0]===14 || js.data().categoryIds[0]===20 || js.data().categoryIds[0]===-1 || js.data().categoryIds[0]===-2 || js.data().categoryIds[0]===18) || (counter===1 && js.data().categoryIds[0]===7)|| (counter===1 && countNews>3 && js.data().categoryIds[0]===4))){
          countNews=js.data().categoryIds[0]===4?countNews=countNews+1:countNews
          if(counter===1){
            if(countNews!==1&&js.data().categoryIds[0]===4){
            setTopArticles(topArticles => [...topArticles, newSho]);
            }else if(js.data().categoryIds[0]!==4){
              setTopArticles(topArticles => [...topArticles, newSho]);
            }
          }else{
            setNextArticle(nextArticle=>[...nextArticle,newSho])
          }
        setLoading(false)
      }
      var topheader=[4,6,8,5,7,12,22] 
      if(topheader.includes(js.data().categoryIds[0])&& counter===1){
        setHeaderArticles(headerArticles => [...headerArticles, newSho]);
      }
       
      })
      
    }catch(error){
   //   console.log(error)
      getLatestArticleWithCategory()
    }
  
}
  useEffect(() => {
    check_login()
    setActiveState('home')
    getLatestArticleWithCategory()
    setCounter(counter+1)
    setLeftPanel("panel panel--left")
    setHideNews('page page--main')
    setMenuIcon('header__icon header__icon--menu open-panel')
    setIsPanelOpen(false)
  },[]);
  var sortArticles=topArticles.sort((a, b) => b.priortiy <a.priortiy ? 1 : a.priortiy === b.priortiy ? 0 : -1)
  const sortNextArticles=nextArticle.sort((a,b)=>b.sub_id >a.sub_id ? 1 : a.sub_id === b.sub_id ? 0 : -1)
  sortArticles=[...sortArticles,...sortNextArticles]
  const sortHeaderArticles=headerArticles.sort((a, b) =>  b.cateId < a.cateId ? 1 : a.cateId === b.cateId ? 0 : -1)
 return (
    //this will help to scroll the page
    <div style={{ height: "100vh", overflow: "auto" }} className="App">
       <div className="body-overlay"></div>
  
  <LeftPanel/>
   
  <RightPanel/>
  <div className={hideNews} data-page="blog">
     <Header/>
     {sortArticles.length?(<div className="page__content page__content--with-header"> 
           <SliderThumbs headerArticles={sortHeaderArticles}/>
           <div className="page__title-bar">
            </div>
           <ListNews topArticles={sortArticles} loadMoredata={loadMoredata} loading={loading}/>
            </div>):(<div className="page__content page__content--with-header">
              <Skeleton/>
            </div>)}
        </div>
    </div>
  );
}

export default Home;
