import React, { useState } from 'react';
import { db } from '../Firebase/FIREBASE_CONFIG';
import firebase from 'firebase/compat/app';
const getCategoryId = (name) => {
    var cateId = ''
    switch (name) {
        case 'agenda':
            cateId = 6;
            break;
        case 'editorials':
            cateId = 8
            break;
        case 'addis-fortune-audio':
            cateId = -4
            break;
        case 'addis-fortune-video':
            cateId = 6
            break;
        case 'breaking-news':
            cateId = -1
            break;
        case 'commentary':
            cateId = 9
            break;
        case 'covid-19':
            cateId = 169
            break;
        case 'digital-first':
            cateId = 149
            break;
        case 'election-2021-coverage':
            cateId = 35465
            break;
        case 'feature-news':
            cateId = 5
            break;
        case 'fineline':
            cateId = 11
            break;
        case 'fortune-news':
            cateId = 4
            break;
        case 'home':
            cateId = 0
            break;
        case 'international-stories':
            cateId = 173
            break;
        case 'interview':
            cateId = 16
            break;
        case 'news-alert':
            cateId = -2
            break;
        case 'news-analysis':
            cateId = 28
            break;
        case 'opinion':
            cateId = 7
            break;
        case 'radar':
            cateId = 14
            break;
        case 'Verbatim':
            cateId = 20
            break;
        case 'Delicate Number':
            cateId = 18
            break;
        case 'view-from-arada':
            cateId = 12
            break;
        case 'viewpoint':
            cateId = 10
            break;
        case 'commentaries':
            cateId = 9
            break;
        case 'sponsored-content':
            cateId = 166
            break;
        case 'life-matters':
            cateId = 19
            break;
        case 'sunday-with-eden':
            cateId = 2
            break;
        case 'films-review':
            cateId = 22
            break;
        case 'Radar':
            cateId = 14
            break;
        case 'main-news':
            cateId = -6
            break;
        case 'ad':
            cateId = -5
            break;
        case 'video':
            cateId = -4
            break;
        case 'audio':
            cateId = -3
            break;
        case 'advertorials':
            cateId = 162
            break;
        case 'delicate-number':
            cateId = 18
            break;
        case 'gallery':
            cateId = 26
            break;
        case 'letter-to-the-editor':
            cateId = 27
            break;
        case 'movie-review':
            cateId = 22
            break;
        case 'news-in-brief':
            cateId = 13
            break;
        case 'obituary':
            cateId = 150
            break;
        case 'photo-caption':
            cateId = 15
            break;
        case 'print-version':
            cateId = 21
            break;
        case 'year-in-review':
            cateId = 38537
            break;
        case 'sponsored-contents-on-addisfortune':
            cateId = 166
            break;
        case 'this-week-top-3-movies':
            cateId = 23
            break;
        case 'uncategorized':
            cateId = 1
            break;
        case 'verbatim':
            cateId = 20
            break;            
        default:
            break
    }
    return cateId
}
const removeDuplicate = (arrays) => {
    var filteredArticles = []
    arrays.forEach(element => {
        const checkIfItsAlreadyInTheList = filteredArticles.findIndex(data => data.sub_id === element.sub_id)
        checkIfItsAlreadyInTheList === -1 && filteredArticles.push(element)
    });
    return filteredArticles;
}
const removeDuplicateCategories = (arrays) => {
    var filteredArticles = []
    arrays.forEach(element => {
        const checkIfItsAlreadyInTheList = filteredArticles.findIndex(data => data.categories === element.categories)
        checkIfItsAlreadyInTheList === -1 && filteredArticles.push(element)
    });
    return filteredArticles;
}
const adf_number = () => {
    var dateTime = new Date('2000-05-07')
    var cudate = new Date()
    const diffTime = Math.abs(cudate - dateTime);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return (Math.floor((diffDays / 7) + 2))
}
const getPriority=(id)=>{
    var rank = ''
    switch (id) {
        case -1:
            rank = 1
            break;
        case -2:
            rank = 2
            break;
        case -3:
            rank = 21
            break;
        case -4:
            rank = 22
            break;
        case -5:
            rank = 4
            break;
        case -6:
            rank = 3
            break;
        case 10:
            rank = 13
            break;
        case 11:
            rank = 16
            break;
        case 12:
            rank = 18
            break;
        case 14:
            rank = 15
            break;
        case 16:
            rank = 12
            break;
        case 166:
            rank = 8
            break;
        case 19:
            rank = 11
            break;
        case 2:
            rank = 9
            break;
        case 20:
            rank = 23
            break;
        case 22:
            rank = 14
            break;
        case 28:
            rank = 17
            break;
        case 4:
            rank = 5
            break;
        case '4-header-only':
            rank = 3
            break;
        case 5:
            rank = 6
            break;
        case 6:
            rank = 10
            break;
        case 7:
            rank = 20
            break;
        case 8:
            rank = 7
            break;
        case 9:
            rank = 19
            break;
        case 'ad-mob-1':
            rank = 4
            break;
        case 'ad-mob-2':
            rank = 9
            break;
        case 'ad-mob-3':
            rank = 13
            break;
        default:
            break
    }
    return rank
}

const GetAds = ({ ads }) => {
    var ad = ""
    switch (ads) {
        case 1:
            ad = <img className = 'adv__image'
            src = 'https://addisfortune.news/adfads2018/united/1.gif'
            alt = '' />
                break;
        case 2:
            ad = <img className = 'adv__image'
            src = 'https://addisfortune.net/adfads2018/awash/2.gif'
            alt = '' />
                break;
        case 3:
            ad = <img className = 'adv__image'
            src = 'https://addisfortune.net/adfads2018/derba/1.gif'
            alt = '' />
                break;
        case 4:
            ad = <img className = 'adv__image'
            src = 'https://addisfortune.news/wp-content/uploads/2021/05/yetal-gif.gif'
            alt = '' />
                break
        case 5:
            ad = <img className = 'adv__image'
            src = 'https://addisfortune.news/wp-content/uploads/2021/12/zemen-low-res.gif'
            alt = '' />
                break
        default:
            ad = <img className = 'adv__image'
            src = 'https://addisfortune.news/wp-content/uploads/2021/07/mobile-Bundle-subscription-new.jpg'
            alt = '' />
                break;
    }
    return ad
}
const getCategoryName = (id) => {
    var cateId = ''
    switch (id) {
        case 6:
            cateId = 'agenda';
            break;
        case 8:
            cateId = 'editorials'
            break;
        case -4:
            cateId = 'addis-fortune-audio'
            break;
        case -1:
            cateId = 'breaking-news'
            break;
        case 9:
            cateId = 'commentary'
            break;
        case 169:
            cateId = 'covid-19'
            break;
        case 149:
            cateId = 'digital-first'
            break;
        case 35465:
            cateId = 'election-2021-coverage'
            break;
        case 5:
            cateId = 'feature-news'
            break;
        case 11:
            cateId = 'fineline'
            break;
        case 4:
            cateId = 'fortune-news'
            break;
        case 0:
            cateId = 'home'
            break;
        case 173:
            cateId = 'international-stories'
            break;
        case 16:
            cateId = 'interview'
            break;
        case -2:
            cateId = 'news-alert'
            break;
        case 28:
            cateId = 'news-analysis'
            break;
        case 7:
            cateId = 'opinion'
            break;
        case 14:
            cateId = 'radar'
            break;
        case 12:
            cateId = 'view-from-arada'
            break;
        case 10:
            cateId = 'viewpoint'
            break;
        case 166:
            cateId = 'sponsored-content'
            break;
        case 19:
            cateId = 'life-matters'
            break;
        case 2:
            cateId = 'sunday-with-eden'
            break;
        case 22:
            cateId = 'films-review'
            break;
        case -6:
            cateId = 'main-news'
            break;
        case -5:
            cateId = 'ad'
            break;
        case -3:
            cateId = 'audio'
            break;
        case 162:
            cateId = 'advertorials'
            break;
        case 18:
            cateId ='delicate-number' 
            break;
        case 26:
            cateId = 'gallery'
            break;
        case 27:
            cateId = 'letter-to-the-editor'
            break;
        case 13:
            cateId = 'news-in-brief'
            break;
        case 150:
            cateId ='obituary' 
            break;
        case 15:
            cateId = 'photo-caption'
            break;
        case 21:
            cateId = 'print-version'
            break;
        case 38537:
            cateId = 'year-in-review'
            break;
        case 23:
            cateId = 'this-week-top-3-movies'
            break;
        case 1:
            cateId = 'uncategorized'
            break;
        case 20:
            cateId = 'verbatim'
            break;
        default:
            break
    }
    return cateId
}
const GetTopHeaderArticles=()=>{
    const [headerArticles,setHeaderArticles] = useState([])
    var topheader=[4,6,8,5,22]
  topheader.forEach(element => {
    
  fetch(`https://us-central1-addis-fortune-ando-v5-10.cloudfunctions.net//api/article/latest/${element}/1}`, {
  method: 'GET',
  headers: new Headers({
    'Authorization': 'Basic ' + btoa(`${'wordpress'}:${'&ziYPnBgZUD8@HB'}`)
  }),
  })
  .then((response) =>response.json()).then(json =>{
    json.map((js,index)=>{
      if(index===0 ){
        const newSlug=js.slug+"_"+js.id
      const cudate=new Date((js.created._seconds+(js.created._nanoseconds)*0.00000001)*1000)
      const catSlug=getCategoryName(element)
      const newSho = {
        sub_id:js.id,
        title: js.title,
        slug:newSlug,
        catSlug:catSlug,
        categories: js.categories[0],
        created: cudate?.toDateString().substr(3),
        images:js.images[0]?js.images[0].url:js.images[1]?.url
      }
      var isItemAlreadyExist=headerArticles.findIndex(article=>article.sub_id===newSho.sub_id)
     isItemAlreadyExist===-1&& setHeaderArticles(headerArticles => [...headerArticles, newSho]);
    }
    })
    //  console.log(updatedSubscriptionItems)
     // setTopArticles(updatedSubscriptionItems);
    });
    });
  }
  const articleRef =(element)=>{
    return db.collection("articles")
    .where("categoryIds", "array-contains", element)
    .orderBy("number", "desc")
    .orderBy("top", "desc")
    .orderBy("created", "desc")
  }
  const getUser=(username)=>{
    return db.collection("subscriptions")
    .where('username', '==', username)
  }
const getAuthorDetail=(id)=>{
    return db.collection('authors')
    .doc(id)
    .get()
}
const addnewComment = (data) => {
    db.collection('comments').add(data);
}
const validatEmail = (email) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(email)
}
const CheckNewsAlertBreaking=async(element)=>{
    const cudate=firebase.firestore.Timestamp.fromDate(new Date(new Date().getTime() - 24*60*60*1000))
    const categoryLatestArticleSnapshot = await db.collection("articles")
          .where("categoryIds", "array-contains", element)
          .where("created", ">=", cudate)
          .orderBy("created", "desc")
          .orderBy("top", "desc")
          .get()
          return categoryLatestArticleSnapshot
}
function isSaturday(date = new Date()) {
    return date.getDay()===6;
  }
const getComment=(postId)=>{
    return db.collection("comments")
    .where('postId', '==', postId)
    .where('visible', '==', true)
    .orderBy("created", "desc")
    .get()
}
export { getCategoryId, removeDuplicate, removeDuplicateCategories, adf_number
    ,getPriority, GetAds, getCategoryName,getComment, articleRef,getUser,getAuthorDetail,validatEmail,addnewComment,isSaturday,CheckNewsAlertBreaking }






