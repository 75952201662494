import React from 'react'

function CategorySkeleton() {
    return (
        <>
        <div style={{height: '205px'}} className='skeleton__card'>
            <div style={{width: '100%'}} className='skeleton__card__one'>
            <div style={{marginTop: '45%',width: '95%',height: '7%'}} className='skeleton__card__title'></div>
            <div style={{marginTop: '2%',width: '55%',height: '7%'}} className='skeleton__card__title'></div>
            </div>
        </div>
        <div className='skeleton'>
            <div className='skeleton__text'>
                <div className='skeleton__title'></div>
                <div className='skeleton__title__2'></div>
                <div className='skeleton__desc'>
                    <div className='skeleton__author'></div>
                    <div className='skeleton__dot'></div>
                    <div className='skeleton__date'></div>
                </div>
            </div>
            <div className='skeleton__image'></div>
        </div>
        <div className='skeleton'>
            <div className='skeleton__text'>
                <div className='skeleton__title'></div>
                <div className='skeleton__title__2'></div>
                <div className='skeleton__desc'>
                    <div className='skeleton__author'></div>
                    <div className='skeleton__dot'></div>
                    <div className='skeleton__date'></div>
                </div>
            </div>
            <div className='skeleton__image'></div>
        </div>
    </>
      )
}

export default CategorySkeleton