import React,{ useEffect, useState } from 'react'
import { useAddisContext } from '../../context/AddisContext'
import arrowright from '../../assets/images/icons/white/arrow-right-circle.svg'
import { AdComponent } from './Custom'
const images = [
    {
        link:'http://www.derbacement.com/',
        img:'https://addisfortune.net/adfads2018/derba/1.gif'},
    {
        link:'https://www.awashbank.com/',
        img:'https://addisfortune.net/adfads2018/awash/2.gif'},
    {
        link:'http://unitedbank.com.et/',
        img:'https://addisfortune.news/adfads2018/united/3.gif'
}
  ];
function LockedContent({urlLink,content}) {
  
  const {loginError,
    loginLoading,setLoginError,
    handleLogin,setLoginLoading}=useAddisContext()
  const [username,setUsername]=useState()
  const [currentImage,setCurrentImage]=useState(0)
  const [username__error,setUsernameError]=useState()
  const [password,setPassword]=useState()
  const [password__error,setPasswordError]=useState()
  const usernameKeyUp=e=>{
    e?.preventDefault();
    if(username){
        setUsernameError()
        setLoginError()
        return
    }
}
const usernameBlur=e=>{
    e?.preventDefault();
    if(!username){
        setUsernameError("*Username can't be empty")
    }else{
        setUsernameError()
    }
}
const passwordKeyUp=e=>{
    e?.preventDefault();
    if(password){
        setPasswordError()
        setLoginError()
    }
}
const passwordBlur=e=>{
    e?.preventDefault();
    if(!password){
        setPasswordError("*Password can't be empty")
    }else{
        setPasswordError()
    }
}
const switchImage=()=> {
    if (currentImage < images.length-1) {
      setCurrentImage(currentImage + 1)
    } else {
        setCurrentImage(0)
    }
  }
   const validateLogin=e=>{
    e?.preventDefault()
    if(username && password){
        setLoginLoading(true)
        handleLogin(username,password,urlLink)
    }else{
        usernameBlur()
        passwordBlur()
    }
   }
   useEffect(()=>{
    const interval= setInterval(switchImage, 10000);
    return () => clearInterval(interval)
   })
  return (
      <>
       <div  style={{padding: '7px 0'}} className="entry">
        <div className='single__content' dangerouslySetInnerHTML={{ __html: content[0].length>200?content[0].slice(0,200)+'...':content[0]+'...' }}></div>
        </div>
      <div className="login-form" style={{width: "90%"}}>
    <div className="swpm-login-widget-form-">
        <div className="swpm-login-form-inner-">
        	<div className="swpm-login-action-msg t-center adf_theme">
                <span style={{fontWeight: "900 !important",fontSize: "16px",color: "#811f1e"}} className="swpm-login-widget-action-msg">Keep reading by creating a free account or logging in.</span>
            </div>
            <br/>
            <div style={{marginBottom: "10px"}} className="swpm-username-input">
                <input type="text" value={username} 
                onBlur={usernameBlur}
                onKeyUp={usernameKeyUp}
                onChange={(e)=>setUsername(e.target.value)} className="classic_form radius bg-gray login-form__input required" id="swpm_user_name" placeholder="Username" size="60" name="swpm_user_name"/>
                <p style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{username__error}</p>
            </div>

            
            <div style={{marginBottom: "10px"}} className="swpm-password-input">
                <input type="password" value={password} 
                onBlur={passwordBlur}
                onKeyUp={passwordKeyUp}
                onChange={(e)=>setPassword(e.target.value)} className="classic_form radius bg-gray login-form__input required" id="swpm_password" size="60" placeholder="Password" name="swpm_password"/>
                <p style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{password__error}</p>
            </div>



            
            <div className="swpm-before-login-submit-section"></div>
            
            <div style={{marginBottom: "10px"}} className="swpm-login-submit">
                <button onClick={validateLogin} 
                className="bg-colored click-effect white extrabold qdr-hover-1 classic_form uppercase radius xxs-mt login-form__submit button button--main button--full login-button" 
                name="swpm-login"> {loginLoading && (
                    <i
                      className="loading-spinner "
                      style={{ marginRight: "5px" }}
                    />
                  )}<span>Login</span></button>
                <small style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{loginError}</small><br/>
            </div><div className="swpm-forgot-pass-link"><a href="#s">
                </a><a id="forgot_pass" className="text-primary" href="https://addisfortune.news/subscription/login.php?action=resset">Forgot Password?</a> 
            </div>
            
        </div>
</div>
</div>
<a href={images[currentImage].link} target="_blank" rel="noreferrer">
<img className = 'adv__image'
 src={images[currentImage].img}
            alt = '' />
</a>

<div style={{marginTop: '15px'}}>
		<div className="subscribe_message" style={{backgroundColor:"lightgray"}}>
			<p style={{color:"black"}}>
				<span style={{fontSize: '26px',fontFamily: 'Merriweather, serif',fontWeight: 'bold',
        letterSpacing:' 1px',display: 'flex', alignIitems: 'center',
        gap: '0.6rem'
    }}>
					Subscribe
					<a href="https://addisfortune.news/subscription?campaign=one_month_free" 
          className="adf_theme" target="_blank" rel="noreferrer"
          style={{fontWeight: 'bold',color:'#4b83b4',display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center'}}> here
					<img style={{    width: '30px',
    height: '20px',
    filter: 'brightness(0.5)'}} src={arrowright} alt=''/>
					</a>
				</span>

			  for the (Addis) Fortune Bundle Subscription and <br/> get a month of exclusive access free of charge.

			</p>
		</div>

	</div>
    <AdComponent slot="9425480604"/>
    </>
  )
}

export default LockedContent