import React,{ useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { db } from '../../Firebase/FIREBASE_CONFIG';
import { getCategoryName, removeDuplicate } from "../../utils/common"
const getApi=(number,q)=>{
    return db.collection("articles")
    .where("categoryIds", "array-contains", number)
    .orderBy("number", "desc")
    .orderBy("top", "desc")
    .orderBy("created", "desc")
    .limit(q).get()
}
const SponserdContent=()=>{
    const [sponsercon,setSponserdCont]=useState([])
    const fetchData=()=>{
       
        getApi(166,1).then((response) =>response.docs).then(json =>{
    json.map((js,index)=>{
        if(index===0 ){
          const newSlug=js.data().slug+"_"+js.id
        const catSlug=getCategoryName(166)
        const newSho = {
          sub_id:js.data().id,
          title: js.data().title,
          slug:newSlug,
          catSlug:catSlug,
        }
        setSponserdCont(newSho);
      }
      })
})
    }
useEffect(()=>{
    fetchData()
})
    return(
        <div className="card card--style-thumb" style={{backgroundColor: "#fef8e6"}}>
                <Link to={`/${sponsercon.catSlug}/${sponsercon.slug}`}>
                    <div>
                    <p className="card__title">
                <Link to={`/${sponsercon.catSlug}/${sponsercon.slug}`}>
                    <br/><b style={{color:"#821e1d"}}>SPONSORED CONTENTS :</b> {sponsercon.title}
                </Link>
            </p>
                    </div>
               </Link>
            </div>)
}
const MyOpinion=()=>{
    const [opinion,setOpinion]=useState([])
    const fetchOpinion=()=>{
        getApi(7,2).then((response) =>response.docs).then(json =>{
           // console.log(json)
            json.map((js,index)=>{
                  const newSlug=js.data().slug+"_"+js.id
                const catSlug=getCategoryName(7)
                const newSho = {
                  sub_id:js.data().id,
                  title: js.data().title,
                  slug:newSlug,
                  catSlug:catSlug,
                  created: js.data().created?.toDate().toDateString(),
                }
                //console.log(newSho)
                setOpinion(opinion => [...opinion, newSho]);
              })
        })
    }
    useEffect(()=>{
        fetchOpinion()
    })
    const filteredArticles=removeDuplicate(opinion)
    return (<>
    <p className="opinion_p" style={{fontWeight: "bold"}}> My Opinion </p>
    <hr className="single_blurb_border" style={{maxWidth: "90px"}}/>
    <div className="two_news_in_row">
   
    {filteredArticles&&filteredArticles?.map((articles,index)=>{
        return(
            <div style={{width: '48%'}}>
                <Link to={`/${articles.catSlug}/${articles.slug}`}>
                    <h4 class="card__title">
                    <Link to={`/${articles.catSlug}/${articles.slug}`}>{articles.title} </Link>
                    
                    </h4>
                    <div class="card__footer">
                        <p style={{fontSize: '1rem'}}>
                            {articles.created}
                        </p>
                    </div>
                    </Link>
                </div>
        )
                })}
          
    </div></>)
}
const GetRadar=()=>{
    const [radar,setRadar]=useState([])
    const fetchData=()=>{
       
        getApi(14,1).then((response) =>response.docs).then(json =>{
    json.map((js,index)=>{
        if(index===0 ){
          const newSlug=js.data().slug+"_"+js.id
        const catSlug=getCategoryName(14)
        const newSho = {
          sub_id:js.data().id,
          title: js.data().title,
          slug:newSlug,
          catSlug:catSlug,
          images:js.data().images[0]?js.data().images[0].url:js.data().images[1]?.url
        }
        setRadar(newSho);
      }
      })
})
    }
useEffect(()=>{
    fetchData()
})
    return(<Link to={`/category/radar`}>
    <div class="image-top" style={{marginBottom: "20px",marginLeft: "auto"}}>
        <img src={radar.images} alt="" title=""/>
        <div class="image-top__details">
            <h1>
                <span class="category-top-title radar-title-on-home" id="home_news_title_0" style={{fontSize: "20px",lineHeight: "33px"}}>
                    {radar.title}</span>
            </h1>
        </div>
    </div>
    </Link>)
}

export {SponserdContent,MyOpinion,GetRadar}