import React, { useEffect, useState } from 'react'
import TopHeader from './Header'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import { db } from '../Firebase/FIREBASE_CONFIG'
function Search() {
  const[leftPanel,setLeftPanel]=useState("panel panel--left")
  const[rightPanel,setRightPanel]=useState("panel panel--right")
  const[hideNews,setHideNews]=useState("page page--main")
  const[ispanelOpen,setIsPanelOpen]=useState(false)
  const[isRightpanelOpen,setIsRightPanelOpen]=useState(false)
  const[menuIcon, setMenuIcon]=useState('header__icon header__icon--menu open-panel')
  const openLeftpanel=()=>{
    if(ispanelOpen===false){
      setHideNews('page page--main with-panel-left-reveal-page')
      setLeftPanel("panel panel--left with-panel-left-reveal-active")
      setMenuIcon('header__icon header__icon--menu open-panel open')
      setIsPanelOpen(true)
    }else{
      setLeftPanel("panel panel--left")
      setHideNews('page page--main')
      setMenuIcon('header__icon header__icon--menu open-panel')
      setIsPanelOpen(false)
    }
 
  }
  const openRightPanel=()=>{
    if(isRightpanelOpen===false){
      setHideNews('page page--main with-panel-right-reveal-page')
      setRightPanel("panel panel--right active with-panel-right-reveal-active")
      setIsRightPanelOpen(true)
    }else{
      setRightPanel("panel panel--right")
      setHideNews('page page--main')
      setIsRightPanelOpen(false)
    }
  }
  useEffect(() => {
    return() =>{
      const snapshot =  db.collection("articles").where("locked", "==", true).get();
      snapshot.forEach(doc => {
          const documentData = doc.data()
          const categoryIds = documentData.categoryIds
          for (let i = 0; i < categoryIds.length; i++) {
              var categoryId = categoryIds[i]
              if (categoryId === -1) {
                  db.collection("articles").doc(doc.id).update({ locked: false })
              }
          }

      })
     }
  },[]);
  return (
    <div style={{ height: "100vh", overflow: "auto" }} className="App">
    <div className="body-overlay"></div>

<LeftPanel leftpanel={leftPanel}/>

<RightPanel rightPanel={rightPanel}/>
<div className={hideNews} data-page="blog">
  <TopHeader openLeftpanel={openLeftpanel} openRightPanel={openRightPanel} menuIcon={menuIcon}/>
  </div>
  </div>

  )
}

export default Search