import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import opinion from '../../assets/images/default_images/opinion.jpg'
// Import Swiper styles

import 'swiper/css';
import 'swiper/css/pagination';
import { removeDuplicateCategories } from '../../utils/common';
import { Link } from 'react-router-dom';
export default function SliderThumbs({headerArticles}) {
    const filteredArticles=removeDuplicateCategories(headerArticles)
  return ( 
          <Swiper
      // install Swiper modules
      modules={[Navigation,Pagination,  Scrollbar, A11y]}
      spaceBetween={5}
      slidesPerView={1.37}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log('swiper')}
      onSlideChange={() => console.log('slide change')}
    >
        {filteredArticles.map(item=>(
            <SwiperSlide> 
<Link to={`/category/${item.catSlug}`}>
<div key={item.sub_id} className="swiper-slide slider-cover__slide slider-cover__slide--34" 
style={{ backgroundImage:`url(${item.cateId===7?opinion:item.images})`, opacity: '0.8'}}>
    <span className="swiper__badge">{item.categories}</span>
    <div className="slider-cover__caption caption">
        <div className="caption__content">
            <div className="page__title-bar">
            </div>
            <h2 className="caption__title caption__title--news">
                <a href="blog-single.html"></a>
            </h2>
        </div>
    </div>
</div>
</Link>
</SwiperSlide>
        ))}
      
    </Swiper>
	
  )
}
