import React from 'react'

function Skeleton() {
  return (
    <>
    <div className='skeleton__card'>
        <div className='skeleton__card__one'>
        <div className='skeleton__card__title'></div>
        </div>
        <div className='skeleton__card__two'>
            <div className='skeleton__card__title__two'></div>
        </div>
    </div>
    <div className='skeleton'>
        <div className='skeleton__text'>
            <div className='skeleton__title'></div>
            <div className='skeleton__title__2'></div>
            <div className='skeleton__desc'>
                <div className='skeleton__author'></div>
                <div className='skeleton__dot'></div>
                <div className='skeleton__date'></div>
            </div>
        </div>
        <div className='skeleton__image'></div>
    </div>
    <div className='skeleton'>
        <div className='skeleton__text'>
            <div className='skeleton__title'></div>
            <div className='skeleton__title__2'></div>
            <div className='skeleton__desc'>
                <div className='skeleton__author'></div>
                <div className='skeleton__dot'></div>
                <div className='skeleton__date'></div>
            </div>
        </div>
        <div className='skeleton__image'></div>
    </div>
</>
  )
}

export default Skeleton