import React from 'react';
import { useEffect } from 'react';
import { useState } from "react";
import { createContext, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { getUser } from '../utils/common';
import { check_login } from '../Firebase/FIREBASE_CONFIG'

const addisContext=createContext()
function useAddisContext(){
    return useContext(addisContext)
}
function AddisContextProvider({children}){
    const navigate=useNavigate()
    const localUser=localStorage.getItem('subscriberUsername');
    const [activeState,setActiveState]=useState('home')
    const [loginError,setLoginError]=useState()
    const [loginLoading,setLoginLoading]=useState(false)
    const [user,setUser]=useState(localUser)
    const [leftPanel, setLeftPanel] = useState("panel panel--left")
    const [rightPanel, setRightPanel] = useState("panel panel--right")
    const [hideNews, setHideNews] = useState("page page--main")
    const [ispanelOpen, setIsPanelOpen] = useState(false)
    const [isRightpanelOpen, setIsRightPanelOpen] = useState(false)
    const [menuIcon, setMenuIcon] = useState('header__icon header__icon--menu open-panel')
    const openLeftpanel=()=>{
        if(ispanelOpen===false){
          setHideNews('page page--main with-panel-left-reveal-page')
          setLeftPanel("panel panel--left with-panel-left-reveal-active")
          setMenuIcon('header__icon header__icon--menu open-panel open')
          setIsPanelOpen(true)
        }else{
          setLeftPanel("panel panel--left")
          setHideNews('page page--main')
          setMenuIcon('header__icon header__icon--menu open-panel')
          setIsPanelOpen(false)
        }
     
      }
      const openRightPanel = () => {
        if (isRightpanelOpen === false) {
            setHideNews('page page--main with-panel-right-reveal-page')
            setRightPanel("panel panel--right active with-panel-right-reveal-active")
            setIsRightPanelOpen(true)
        } else {
            setRightPanel("panel panel--right")
            setHideNews('page page--main')
            setIsRightPanelOpen(false)
        }
    }
  const handleLogin=async(username,password,urlLink=null)=>{
    getUser(username).get().then((collection)=>{
      const coll=collection.docs 
      const email=coll[0].data().email
      fetch(`https://addisfortune.news/mobile_api/swmp_login_to_mobile.php?email=${email}&pass=${password}`)
      .then(res => res.json())
      .then((responseJson) =>{
        if(responseJson.status==="logged_in"){
          if(collection.docs.length>0){
            localStorage.setItem('subscriberUsername', username)
            if(urlLink){
              navigate(urlLink)
            }else{
              navigate(`/`)
            }
            
            setUser(localStorage.getItem('subscriberUsername'))
          }
          setLoginLoading(false)
        }else{
          setLoginError("Password/Email Login Error")
          setLoginLoading(false)
        }
      } );
      
    })
  }
  useEffect(()=>{
    check_login()
    setUser(localStorage.getItem('subscriberUsername'))
  },[])
    return (
    <addisContext.Provider 
    value={{activeState,
      user,
      setActiveState
    ,openLeftpanel,
    openRightPanel,
    setHideNews,
    setLeftPanel,
    setMenuIcon,
    setIsPanelOpen,
    setRightPanel,
    setIsRightPanelOpen,
    handleLogin,
    setUser,
    loginError,
    setLoginError,
    loginLoading,
    setLoginLoading, 
    leftPanel,
    rightPanel,
    hideNews,
    menuIcon}}>
        {children}
    </addisContext.Provider>)
}
export{useAddisContext,AddisContextProvider}