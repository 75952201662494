import React from 'react'
import Takele from '../assets/images/photos/Takele-uma.jpg'
import { Link } from 'react-router-dom'
import { GetAds, removeDuplicate } from '../utils/common'
import arrowright from '../assets/images/icons/white/arrow-right-circle.svg'
import { GetRadar, MyOpinion, SponserdContent } from '../pages/Home/Custom'
import { AdComponent } from '../pages/Single/Custom'
function ListNews({topArticles,loadMoredata,loading}) {
    const filteredArticles=removeDuplicate(topArticles)
    var count =0
    var ads=0
  return (
      
     <div className="cards cards--12" id="loadlist-blog">
{filteredArticles?.map((articles,index)=>{
    
if(count<2){
    count+=1
    return(<>
        {index===2?<SponserdContent/>:''}
        {index===7?(<MyOpinion/>):''}
        {index===7?(<GetRadar/>):''}
        {index===5&&<AdComponent slot="5925329343"/>}
        <Link key={articles.id} to={`/${articles.catSlug}/${articles.slug}`}>
        {articles.images?(
            <div className="card card--style-thumb">
            <div className="card__thumb card__thumb--round-corners-top mb-0">
                <a href="blog-single.html"><img src={articles.images?articles.images:Takele} alt="" title="" /></a>
                <div className="card__top-right-info">
                </div>
            </div>
            <div className="card__details card__details--bg card__details--round-corners-bottom">
                <h4 className="card__title"><a href="blog-single.html" dangerouslySetInnerHTML={{ __html: articles.title }}></a></h4>
                <div className="card__footer">
                    <p style={{color:'#811f1e',fontSize:'.9rem'}}>{articles.categories}</p>
                    <span style={{fontSize:'.9rem'}}>{articles.created}</span>
                </div>
            </div>
        </div>
        ):(
            <div className="card card--style-thumb">
            <a href="blog-single.html">
            <div className="card__details card__details--bg card__details--round-corners-bottom">
                <h4 className="card__title">
                <a href="blog-single.html" dangerouslySetInnerHTML={{ __html: articles.title }}>
                 </a>   
                </h4>
                <div className="news_date_and_category">
                <div className='excerpt__put' style={{fontSsize: '11px',color: '#484646'}} dangerouslySetInnerHTML={{ __html: articles.excerpt }}></div>
                </div>
                <div className="card__footer">
                    <div className="swiper__badge__container">
                        <p className="news__badge" style={{color:'#811f1e',fontSize:'.9rem'}}> {articles.categories} </p>
                    </div>
                    <span style={{fontSize:'.9rem'}}> {articles.created} </span>
                </div>
            </div>
            </a>
    </div>
        )}
    
</Link>
</>)
}else{
    ads+=1
    count=0
    return(
        <>
        {index===2?(<SponserdContent/>):''}
        {index===7?(<MyOpinion/>):''}
        {index===5&&<AdComponent slot="5925329343"/>}
        <Link key={articles.id} to={`/${articles.catSlug}/${articles.slug}`}>
            {articles.images?(
                <div className="card card--style-thumb">
                <div className="card__thumb card__thumb--round-corners-top mb-0">
                    <a href="blog-single.html"><img src={articles.images?articles.images:Takele} alt="" title="" /></a>
                    <div className="card__top-right-info">
                    </div>
                </div>
                <div className="card__details card__details--bg card__details--round-corners-bottom">
                    <h4 className="card__title"><a href="blog-single.html" dangerouslySetInnerHTML={{ __html: articles.title }}></a></h4>
                    <div className="card__footer">
                        <p style={{color:'#811f1e',fontSize:'.9rem'}}>{articles.categories}</p>
                        <span style={{fontSize:'.9rem'}}>{articles.created}</span>
                    </div>
                </div>
            </div>
            ):(
                   <div className="card card--style-thumb">
                       <a href="blog-single.html">
                       <div className="card__details card__details--bg card__details--round-corners-bottom">
                           <h4 className="card__title">
                           <a href="blog-single.html" dangerouslySetInnerHTML={{ __html: articles.title }}>
                            </a>   
                           </h4>
                           <div className="news_date_and_category">
                           <div className='excerpt__put' style={{fontSsize: '11px',color: '#484646'}} dangerouslySetInnerHTML={{ __html: articles.excerpt }}></div>
                           </div>
                           <div className="card__footer">
                               <div className="swiper__badge__container">
                                   <p className="news__badge" style={{color:'#811f1e',fontSize:'.9rem'}}> {articles.categories} </p>
                               </div>
                               <span style={{fontSize:'.9rem'}}> {articles.created} </span>
                           </div>
                       </div>
                       </a>
               </div>
            )}
        
    </Link>
    <GetAds ads={ads}/></>)
}
    
})}
 <div style={{marginLeft: '-10px',marginRight: "-10px"}}>
          <div className="subscribe_message" style={{backgroundColor:"lightgray"}}>
            <p style={{color:"black"}}>
              <span style={{fontSize: "23px",fontFamily: 'Merriweather, serif',fontWeight: 'bold',
              letterSpacing: "1px",display: 'flex',gap: '1rem',alignItems: 'center'}}>
              {loading?'Loding...':'Read more'}
              {filteredArticles.length>0&&(<div onClick={loadMoredata} className="adf_theme" style={{fontWeight: 'bold',
              color:"#4b83b4",display: 'flex',justifyContent: 'space-evenly',alignItems: 'center'}}>  {loading?'':'here'}
                {loading?'':(<img style={{    width: '30px',
    height: '20px',
    filter: 'brightness(0.5)'}} src={arrowright} alt=''/>)}
                </div>)}
              </span>
              <br/>
              Get more in-depth contents from (Addis) Fortune.
            </p>
          </div>
        </div>
        <AdComponent slot="9425480604"/>
</div>
  )
}

export default ListNews