import React from 'react'
import { GetAds } from '../../utils/common';
import { Link } from 'react-router-dom';
import { removeDuplicate } from '../../utils/common'
import arrowright from '../../assets/images/icons/white/arrow-right-circle.svg'
import { AdComponent } from '../Single/Custom';
function CategoryList({topArticles,loadMoredata,loading}) {
    var count =0
    var ads=0
    const filteredArticles=removeDuplicate(topArticles)
   // console.log(topArticles)
  return (
      
     <div className="cards cards--12" id="loadlist-blog">
{filteredArticles?.map((articles,index)=>{
    if(index>0){
        if(count<2){
            count+=1
     return(
        <>
        {index===5&&<AdComponent slot="5925329343"/>}
         <Link key={index} to={`/${articles.catSlug}/${articles.slug}`}>
             {articles.images?( <div className="card card--style-thumb">
        <div className="card__thumb card__thumb--round-corners-top mb-0">
            <img src={articles.images} alt="" title="" />
            <div className="card__top-right-info">
            </div>
        </div>
        <div className="card__details card__details--bg card__details--round-corners-bottom">
            <h4 className="card__title" dangerouslySetInnerHTML={{ __html: articles.title }}></h4>
            <div className="card__footer">
                {articles.authors?(<p style={{color:'#811f1e',fontSize:'.9rem'}}>{"By "+articles.authors}</p>):<p style={{fontSize:'.9rem'}}>{articles.created}</p>}
                {articles.authors?(<span style={{fontSize:'.9rem'}}>{articles.created}</span>):''}
            </div>
        </div>
    </div>):( <div className="card card--style-thumb">
            <div className="card__details card__details--bg card__details--round-corners-bottom">
                <h4 className="card__title" dangerouslySetInnerHTML={{ __html: articles.title }}>
                </h4>
                <div className="news_date_and_category">
                <div className='excerpt__put' style={{fontSsize: '11px',color: '#484646'}} dangerouslySetInnerHTML={{ __html: articles.excerpt }}></div>
                </div>
                <div className="card__footer">
                {articles.authors?(<p style={{color:'#811f1e',fontSize:'.9rem'}}>{"By "+articles.authors}</p>):<p style={{fontSize:'.9rem'}}>{articles.created}</p>}
                {articles.authors?(<span style={{fontSize:'.9rem'}}>{articles.created}</span>):''}
                </div>
            </div>
    </div>)}
   
    </Link></>
     )
    }else{
        ads+=1
        count=0
        return(
            <>
            {index===5&&<AdComponent slot="5925329343"/>}
             <Link key={index} to={`/${articles.catSlug}/${articles.slug}`}>
             {articles.images?( <div className="card card--style-thumb">
             <div className="card__thumb card__thumb--round-corners-top mb-0">
                 <img src={articles.images} alt="" title="" />
                 <div className="card__top-right-info">
                 </div>
             </div>
             <div className="card__details card__details--bg card__details--round-corners-bottom">
                 <h4 className="card__title" dangerouslySetInnerHTML={{ __html: articles.title }}></h4>
                 <div className="card__footer">
                 {articles.authors?(<p style={{color:'#811f1e',fontSize:'.9rem'}}>{"By "+articles.authors}</p>):<p style={{fontSize:'.9rem'}}>{articles.created}</p>}
                {articles.authors?(<span style={{fontSize:'.9rem'}}>{articles.created}</span>):''}
                 </div>
             </div>
         </div>
        
         ):( <div className="card card--style-thumb">
             <div className="card__details card__details--bg card__details--round-corners-bottom">
                 <h4 className="card__title" dangerouslySetInnerHTML={{ __html: articles.title }}>
                 </h4>
                 <div className="news_date_and_category">
                    <div className='excerpt__put' style={{fontSsize: '11px',color: '#484646'}} dangerouslySetInnerHTML={{ __html: articles.excerpt }}></div>
                 </div>
                 <div className="card__footer">
                 {articles.authors?(<p style={{color:'#811f1e',fontSize:'.9rem'}}>{"By "+articles.authors}</p>):<p style={{fontSize:'.9rem'}}>{articles.created}</p>}
                {articles.authors?(<span style={{fontSize:'.9rem'}}>{articles.created}</span>):''}
                 </div>
             </div>
     </div>)}
     <GetAds ads={ads}/>
            </Link>
            </>
             )
             
    }}
    })}
    <div style={{marginLeft: '-10px',marginRight: "-10px"}}>
          <div className="subscribe_message" style={{backgroundColor:"lightgray"}}>
            <p style={{color:"black"}}>
              <span style={{fontSize: "23px",fontFamily: 'Merriweather, serif',fontWeight: 'bold',
              letterSpacing: "1px",display: 'flex',gap: '1rem',alignItems: 'center'}}>
              {loading?'Loding...':'Read more'}
              {filteredArticles.length>0&&(<div onClick={loadMoredata} className="adf_theme" style={{fontWeight: 'bold',
              color:"#4b83b4",display: 'flex',justifyContent: 'space-evenly',alignItems: 'center'}}>  {loading?'':'here'}
                {loading?'':(<img style={{    width: '30px',
    height: '20px',
    filter: 'brightness(0.5)'}} src={arrowright} alt=''/>)}
                </div>)}
              </span>
              <br/>
              Get more in-depth contents from (Addis) Fortune.
            </p>
          </div>
        </div>
        <AdComponent slot="9425480604"/>
  </div>
  )
}

export default CategoryList