import React, { useEffect, useState } from 'react'
import { db } from '../../Firebase/FIREBASE_CONFIG'
import { useParams } from 'react-router-dom'
import TopHeader from '../../component/Header'
import LeftPanel from '../../component/LeftPanel'
import RightPanel from '../../component/RightPanel'
import { getCategoryId } from '../../utils/common'
import CategoryList from './CategoryList'
import VerbatimCard from './VerbatimCard'

function Verbatim() {
    const { name } = useParams()
    const[leftPanel,setLeftPanel]=useState("panel panel--left")
    const[rightPanel,setRightPanel]=useState("panel panel--right")
    const[hideNews,setHideNews]=useState("page page--main")
    const[ispanelOpen,setIsPanelOpen]=useState(false)
    const[isRightpanelOpen,setIsRightPanelOpen]=useState(false)
    const[menuIcon, setMenuIcon]=useState('header__icon header__icon--menu open-panel')
    const [topArticles, setTopArticles] = useState([])
    const openLeftpanel=()=>{
      if(ispanelOpen===false){
        setHideNews('page page--main with-panel-left-reveal-page')
        setLeftPanel("panel panel--left with-panel-left-reveal-active")
        setMenuIcon('header__icon header__icon--menu open-panel open')
        setIsPanelOpen(true)
      }else{
        setLeftPanel("panel panel--left")
        setHideNews('page page--main')
        setMenuIcon('header__icon header__icon--menu open-panel')
        setIsPanelOpen(false)
      }
   
    }
    const openRightPanel=()=>{
      if(isRightpanelOpen===false){
        setHideNews('page page--main with-panel-right-reveal-page')
        setRightPanel("panel panel--right active with-panel-right-reveal-active")
        setIsRightPanelOpen(true)
      }else{
        setRightPanel("panel panel--right")
        setHideNews('page page--main')
        setIsRightPanelOpen(false)
      }
    }
    const getdata=()=>{
    
      
    }
    useEffect(() => {
      return()=>{
        var categoryId=20
        db.collection("articles")
        .where("categoryIds", "array-contains", categoryId)
        .orderBy("number", "desc")
        .orderBy("top", "desc")
        .orderBy("created", "desc")
        .get()
        .then(js => {
         // const cudate=new Date((js.created._seconds+(js.created._nanoseconds)*0.00000001)*1000)
          const newSlug=js.slug+"_"+js.id
          const newSho = {
            sub_id:js.id,
            title: js.data().title,
            authors: js.data().authors[0],
            created: js.data().created?.toDate().toDateString(),
            slug:newSlug,
            images:js.data().images[0]?js.data().images[0].url:js.data().images[1]?.url
          }
        });
      var nums=20
      fetch(`https://us-central1-addis-fortune-ando-v5-10.cloudfunctions.net//api/article/latest/${nums}/30`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Basic ' + btoa(`${'wordpress'}:${'&ziYPnBgZUD8@HB'}`)
      }),
    })
      .then((response) =>response.json()).then(json =>{
        json.map((js)=>{
          const cudate=new Date((js.created._seconds+(js.created._nanoseconds)*0.00000001)*1000)
          const newSlug=js.slug+"_"+js.id
          const newSho = {
            sub_id:js.id,
            title: js.title,
            authors: js.authors[0],
            created: cudate?.toDateString().substr(3),
            slug:newSlug,
            images:js.images[0]?js.images[0].url:js.images[1]?.url
          }
          setTopArticles(topArticles => [...topArticles, newSho]);
         // setTopArticles(updatedUrlsArray)
          
         
        })
      //  console.log(updatedSubscriptionItems)
       // setTopArticles(updatedSubscriptionItems);
  
      });
      }
    },[])
    return (
      //this will help to scroll the page
      <div style={{ height: "100vh", overflow: "auto" }} className="App">
         <div className="body-overlay"></div>
    
         <LeftPanel leftpanel={leftPanel}/>
         <RightPanel rightPanel={rightPanel}/>
    <div id="panel-right"></div>
    <div className={hideNews} data-page="blog">
       <TopHeader  openLeftpanel={openLeftpanel} Topname={'Verbatim'}menuIcon={menuIcon}/> 
       <div style={{paddingTop: '59px'}} className="page__content page__content--with-header"> 
       <VerbatimCard topArticles={topArticles}/>
             <div style={{    marginTop: '3rem'}} className="page__title-bar">
              </div>
             <CategoryList topArticles={topArticles}/>
              </div>
          </div>
      </div>
    )
}

export default Verbatim