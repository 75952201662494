import React from 'react'
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Configure,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { getCategoryName } from '../utils/common';
import { useAddisContext } from '../context/AddisContext';
const searchClient = algoliasearch(
	'UFA6WYQQ33',
	'b7c6ccafe7e365121b86694fc7072959'
  );
function RightPanel() {
  const {rightPanel}=useAddisContext()
  return ( 
    <div className={rightPanel}>
     
      <div className="ais-InstantSearch">
        <InstantSearch indexName="articles" searchClient={searchClient}>
		    <Configure hitsPerPage={8} />
		
         <div className="search">
         <div className="search__form">
            <SearchBox />
            </div>
            </div>
            <h3 style={{paddingLeft:'20px',marginBottom:'10px'}}>Recommended Articles</h3>
            <Hits hitComponent={Hit} />
            <Pagination />
        </InstantSearch>
      </div>
      
</div>  
  )
}
function Hit(props) {
  const catSlug=getCategoryName(props.hit.categoryIds[0])
  const newSlug=props.hit.slug+"_"+props.hit.id
	return (
	  <div style={{paddingLeft:'20px'}}>
      <Link  key={props.hit.id} to={`/${catSlug}/${newSlug}`}>
                <div className="search-result">
                    <div className="search-result__thumb">
                        <img src={props.hit.images[0]?.url} alt="" title=""/>
                    </div>
                    <div className="search-result__details" dangerouslySetInnerHTML={{ __html: props.hit.title }}>
                    </div>
                </div>
            </Link>
	  </div>
	);
  }
  
  Hit.propTypes = {
	hit: PropTypes.object.isRequired,
  };

export default RightPanel