import React,{useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,  Scrollbar, A11y } from 'swiper';
import close from '../../assets/images/icons/white/close.svg'
import left from '../../assets/images/icons/white/arrow-left.svg'
import right from '../../assets/images/icons/white/arrow-right.svg'
import { AdComponent } from './Custom';
function Gallery({mainArticles,content}) {
    const[modalOpen,setModalOpen]=useState(false)
    const[modalContent,setModalContent]=useState('')
    const [counter,setCounter]=useState(1)
    const openModal=(item,index)=>{
        setModalOpen(true)
        setCounter(index)
        setModalContent(item)
        console.log(item)
    }
    const closeModal=()=>{
        setModalOpen(false)
        setModalContent('')
    }
    const nextImage=(item)=>{
        if(counter===item.length-1){
            setCounter(0)
        }else{
            setCounter(counter+1)
        }
    }
    const prevImage=(item)=>{
        if(counter===0){
            setCounter(item.length-1)
        }else{
            setCounter(counter-1)
        }
    }
    const Modal=({open,content,closeModal})=>{
        const className=open?'modal open-modal':'close-modal'
        return(
            <>
            <div className={className}>
                <section className='modal-main'>
                <img style={{width: "100%",height: "100%"}} src={content[counter]?.url} alt="imag"/>
                  
                </section>
                <p style={{position: "absolute",left:'10%',top:'10%',color: "#fff"}}>{counter+1}/{content.length}</p>
                <img style={{width: "15px",height: "15px",position: "absolute",right:'10%',top:'10%'}} onClick={closeModal} src={close} alt="close"/>
             <img style={{width: "22px",height: "27px",position: "absolute",right:'7%',top:'50%'}} onClick={()=>nextImage(content)} src={right} alt="close"/>
             <img style={{width: "22px",height: "27px",position: "absolute",left:'7%',top:'50%'}} onClick={()=>prevImage(content)}  src={left} alt="close"/>
             
            </div>
             </>
        )
    }
  return (
    <div>
     <div class="page__content page__content--with-header page__content--with-bottom-nav" data-page="photos" style={{
    color: "black",
    textAlign: 'center',
     marginTop: '10px',
    padding: '0px 0',marginBottom: "-45px"}}>

<div class="entry" style={{color:"black",textAlign: "center",fontFamily: 'Merriweather, serif'}}>
    <h1 style={{marginBottom: '0px',fontSize:'33px',
    textAlign: 'left',
    marginLeft: '15px'}}>{mainArticles.title}</h1>
    <span class="d-block pb-10 pt-10">{mainArticles.created}</span>
    <span class="d-block" style={{padding: "10px 30px 0px 30px"}}></span>
</div>

<div class="post_body" style={{marginTop:" -15px"}}>
    <p style={{color: "black",textAlign: "left",
    marginLeft: "15px",lineHeight: "140%",marginRight: "15px",fontSize: "1.47rem"}} class="welcome">
       {content}        </p>

    <ul style={{marginLeft: "15px",marginRight: "15px",width:'auto'}} id="photogallery" class="photo-gallery photo-gallery--13">
    {mainArticles?.images?.map((i,index)=>{  
        return( <li>
                <div onClick={()=>openModal(mainArticles?.images,index)} title={i?.caption}>
                    <img src={i.url} alt="imag"/>
                </div>
            </li>)
})}
                        
                </ul>
</div>



<div class="entry">
    
<div style={{marginTop: "-25px"}}>
    <h3 class="news_list_titles">Read More</h3>
</div>
<div class="swiper-container slider-thumbs slider-init mb-20 s0 swiper-container-initialized swiper-container-horizontal s1" data-paginationtype="bullets" data-spacebetweenitems="10" data-itemsperview="auto" style={{paddingLeft:"10px",width:'auto'}}>
    <div class="swiper-wrapper" style={{transform: "translate3d(0px, 0px, 0px)"}}>
    <Swiper
      // install Swiper modules
      modules={[Navigation,  Scrollbar, A11y]}
      spaceBetween={5}
      slidesPerView={2.37}
      onSwiper={(swiper) => console.log('swiper')}
      onSlideChange={() => console.log('slide change')}
    ><SwiperSlide> 
                        <div class="swiper-slide slider-thumbs__slide slider-thumbs__slide--2h swiper-slide-active" style={{marginRight:" 10px"}}>
            <div class="slider-thumbs__image slider-thumbs__image--round-corners-top">
                    <a href="https://addisfortune.news/national-dialogue-what-national-dialogue/">
                        <img src="https://addisfortune.news/wp-content/uploads/2022/08/Editorial-cartoon-2.jpg" alt="" title=""/>
                    </a>
                </div>
                <div class="card__details card__details--bg card__details--round-corners-bottom">
                    <h4 class="card__title" style={{marginTop: "5px"}}>
                        <a href="https://addisfortune.news/national-dialogue-what-national-dialogue/">
                            National Dialogue! What Nation...                            </a>
                    </h4>
                    <p class="card__text">
                        Editorial                        </p>
                </div>
            </div></SwiperSlide>
            <SwiperSlide>    <div class="swiper-slide slider-thumbs__slide slider-thumbs__slide--2h swiper-slide-next" style={{marginRight:" 10px"}}>
            <div class="slider-thumbs__image slider-thumbs__image--round-corners-top">
                    <a href="https://addisfortune.news/marks-to-erase-tattoo-removal-business-booms/">
                        <img src="https://addisfortune.news/wp-content/uploads/2022/07/Tattoo-removal-m.jpg" alt="" title=""/>
                    </a>
                </div>
                <div class="card__details card__details--bg card__details--round-corners-bottom">
                    <h4 class="card__title" style={{marginTop: "5px"}}>
                        <a href="https://addisfortune.news/marks-to-erase-tattoo-removal-business-booms/">
                            Marks to Erase: Tattoo Removal...                            </a>
                    </h4>
                    <p class="card__text">
                        Featured                        </p>
                </div>
            </div></SwiperSlide>
            <SwiperSlide>           <div class="swiper-slide slider-thumbs__slide slider-thumbs__slide--2h" style={{marginRight:" 10px"}}>
            <div class="slider-thumbs__image slider-thumbs__image--round-corners-top">
                    <a href="https://addisfortune.news/agenda-domestic-smartphone-assemblers-dial-it-in/">
                        <img src="https://addisfortune.news/wp-content/uploads/2022/08/Electronics-stores-in-Megenagna-1.jpg" alt="" title=""/>
                    </a>
                </div>
                <div class="card__details card__details--bg card__details--round-corners-bottom">
                    <h4 class="card__title" style={{marginTop: "5px"}}>
                        <a href="https://addisfortune.news/agenda-domestic-smartphone-assemblers-dial-it-in/">
                            Agenda: Domestic Smartphone As...                            </a>
                    </h4>
                    <p class="card__text">
                        Agenda                        </p>
                </div>
            </div></SwiperSlide>
            <SwiperSlide>        <div class="swiper-slide slider-thumbs__slide slider-thumbs__slide--2h" style={{marginRight:" 10px"}}>
            <div class="slider-thumbs__image slider-thumbs__image--round-corners-top">
                    <a href="https://addisfortune.news/addis-abeba-university-reclaims-financial-operational-autonomy/">
                        <img src="https://addisfortune.news/wp-content/uploads/2022/08/AAU-3.jpg" alt="" title=""/>
                    </a>
                </div>
                <div class="card__details card__details--bg card__details--round-corners-bottom">
                    <h4 class="card__title" style={{marginTop: "5px"}}>
                        <a href="https://addisfortune.news/addis-abeba-university-reclaims-financial-operational-autonomy/">
                            Addis Abeba University Reclaim...                            </a>
                    </h4>
                    <p class="card__text">
                        Fortune News                        </p>
                </div>
            </div></SwiperSlide>
            </Swiper>
                </div></div>
                <Modal
        open={modalOpen}
        content={modalContent}
        closeModal={closeModal}/>
</div>
</div>
<AdComponent slot="9425480604"/>
    </div>
  )
}

export default Gallery
