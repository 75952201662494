import React from 'react'
import search from '../assets/images/icons/white/search.svg'
import logo from '../assets/images/icons/adf_logo-inverse.png'
import { useAddisContext } from '../context/AddisContext'

function TopHeader({Topname=''}) {
  const {openLeftpanel,openRightPanel,menuIcon}=useAddisContext()
  return (
        <header className="header header--page header--fixed">
            <div className="header__inner">
                <div onClick={openLeftpanel} className={menuIcon} data-panel="left"><span></span><span></span><span></span><span></span><span></span><span></span>
                </div>
                <div className="header__logo header__logo--text"><a href="#h">{Topname?Topname:(<img style={{opacity: "1", height: "30px"}} src={logo} alt=""/>)}</a></div>
                <div className="header__icon open-panel" onClick={openRightPanel} data-panel="right"><img src={search} alt="" title="" /></div>
            </div>
        </header>
	
  )
}

export default TopHeader