import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/Header';
import LeftPanel from '../../component/LeftPanel'
import RightPanel from '../../component/RightPanel'
import { useAddisContext } from '../../context/AddisContext'
import arrowright from '../../assets/images/icons/white/arrow-right-circle.svg'
function Login() {
    const navigate=useNavigate()
    const {setActiveState,user,openLeftpanel,openRightPanel,
        leftPanel,rightPanel,hideNews,menuIcon,
        setHideNews,setLeftPanel,setMenuIcon,handleLogin,loginError,
        loginLoading,setLoginLoading,setLoginError}=useAddisContext()
    const [username,setUsername]=useState()
    const [username__error,setUsernameError]=useState()
    const [password,setPassword]=useState()
    const [password__error,setPasswordError]=useState()
    const usernameKeyUp=e=>{
        e?.preventDefault();
        if(username){
            setUsernameError()
            setLoginError()
            return
        }
    }
    const usernameBlur=e=>{
        e?.preventDefault();
        if(!username){
            setUsernameError("*Username can't be empty")
        }else{
            setUsernameError()
        }
    }
    const passwordKeyUp=e=>{
        e?.preventDefault();
        if(password){
            setPasswordError()
            setLoginError()
        }
    }
    const passwordBlur=e=>{
        e?.preventDefault();
        if(!password){
            setPasswordError("*Password can't be empty")
        }else{
            setPasswordError()
        }
    }
    const validateLogin=e=>{
        e?.preventDefault()
        if(username && password){
            setLoginLoading(true)
            handleLogin(username,password)
        }else{
            usernameBlur()
            passwordBlur()
        }
    }
    useEffect(()=>{
        if(user) navigate(`/`)
        setActiveState('')
        setLeftPanel("panel panel--left")
        setHideNews('page page--main')
        setMenuIcon('header__icon header__icon--menu open-panel')
    },[])
  return (
    <div style={{ height: "100vh", overflow: "auto" }} className="App">
       <div className="body-overlay"></div>
       <LeftPanel leftpanel={leftPanel}/>
   
  <RightPanel rightPanel={rightPanel}/>
  <div className={hideNews} data-page="blog">
     <Header openLeftpanel={openLeftpanel} openRightPanel={openRightPanel} menuIcon={menuIcon}/>
     <div className="login-form" style={{paddingTop: "150px"}}><a href="#s">
    <h1 style={{fontSize: "2.5rem"}}>Login</h1>
	</a><div className="swpm-login-widget-form-">
        <div className="swpm-login-form-inner-">
        	<div className="swpm-login-action-msg t-center adf_theme">
                <span className="swpm-login-widget-action-msg"></span>
            </div>
            <br/>
            <div style={{marginBottom: "10px"}} className="swpm-username-input">
                <input type="text" value={username} 
                onBlur={usernameBlur}
                onKeyUp={usernameKeyUp}
                onChange={(e)=>setUsername(e.target.value)} className="classic_form radius bg-gray login-form__input required" id="swpm_user_name" placeholder="Username" size="60" name="swpm_user_name"/>
                <p style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{username__error}</p>
            </div>

            
            <div style={{marginBottom: "10px"}} className="swpm-password-input">
                <input type="password" value={password} 
                onBlur={passwordBlur}
                onKeyUp={passwordKeyUp}
                onChange={(e)=>setPassword(e.target.value)} className="classic_form radius bg-gray login-form__input required" id="swpm_password" size="60" placeholder="Password" name="swpm_password"/>
                <p style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{password__error}</p>
            </div>



            
            <div className="swpm-before-login-submit-section"></div>
            
            <div style={{marginBottom: "10px"}} className="swpm-login-submit">
                <button onClick={validateLogin} 
                className="bg-colored click-effect white extrabold qdr-hover-1 classic_form uppercase radius xxs-mt login-form__submit button button--main button--full login-button" 
                name="swpm-login"> {loginLoading && (
                    <i
                      className="loading-spinner "
                      style={{ marginRight: "5px" }}
                    />
                  )}<span>Login</span></button>
                <small style={{color: 'red',fontSize: '16px',marginTop:'1rem'}}>{loginError}</small><br/>
            </div><div className="swpm-forgot-pass-link"><a href="#s">
                </a><a id="forgot_pass" className="text-primary" href="https://addisfortune.news/subscription/login.php?action=resset">Forgot Password?</a> 
            </div>
            
        </div>
</div>


	
</div>
<div style={{width: "100%",position: "fixed",bottom: "0"}}>
		<div className="subscribe_message" style={{backgroundColor:"lightgray"}}>
			<p style={{color:"black"}}>
				<span style={{fontSize: '26px',fontFamily: 'Merriweather, serif',fontWeight: 'bold',
        letterSpacing:' 1px',display: 'flex', alignIitems: 'center',
        gap: '0.6rem'
    }}>
					Subscribe
					<a href="https://addisfortune.news/subscription?campaign=one_month_free" 
          className="adf_theme" target="_blank" rel="noreferrer"
          style={{fontWeight: 'bold',color:'#4b83b4',display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center'}}> here
					<img style={{    width: '30px',
    height: '20px',
    filter: 'brightness(0.5)'}} src={arrowright} alt=''/>
					</a>
				</span>

			  for the (Addis) Fortune Bundle Subscription and <br/> get a month of exclusive access free of charge.

			</p>
		</div>

	</div>
     </div>
       </div>
  )
}

export default Login
