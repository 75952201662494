// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';



const firebaseConfig = {

    apiKey: "AIzaSyCY0O4bZJF4Bd-Kb5uYSUWXLjXhsHclQs0",

    authDomain: "addis-fortune-ando-v5-10.firebaseapp.com",

    databaseURL: "https://addis-fortune-ando-v5-10.firebaseio.com",

    projectId: "addis-fortune-ando-v5-10",

    storageBucket: "addis-fortune-ando-v5-10.appspot.com",

    messagingSenderId: "168197504343",

    appId: "1:168197504343:web:d6f0283df3d744296f9212",

    measurementId: "G-WQTV1R96QG"

};


firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();

const check_login = () => {
    firebase.auth().signInAnonymously()
        .then(function() {

            // console.log('Logged in as Anonymous!')

        }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
        });
}




export { db, firebaseConfig, auth, check_login };

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);