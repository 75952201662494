import React from 'react'
import { Link } from 'react-router-dom'
import defaultImage from '../../assets/images/photos/defaultImage.jpg'
function Card({topArticles}) {
  return (
    <div className="image-top">
      <Link to={`/${topArticles[0]?.catSlug}/${topArticles[0]?.slug}`}>
                <img src={topArticles[0].images?topArticles[0].images:defaultImage} alt="" title="" />

                <div className="image-top__details">
                    <h1 style={{paddingLeft:'1.5rem'}} >
                      <span>{topArticles[0]?.title}
                      </span></h1>

                </div>
                </Link>
            </div>
  )
}

export default Card