import React,{ useEffect, useState } from "react"
const AuthorDetail=({authorDetail})=>{
    return(<>
        {Object.keys(authorDetail).length !== 0&&(<div className="author-description-container"><div className="author-descriptions">
                        <span className="user-line">
                            <img src="https://addisfortune.news/wp-content/themes/addis-fortune/images/defaultPhots/120.jpg" alt="" title="" style={{width: "60px"}}/>

                            </span>
                        <div className="card__details" style={{margin:"auto 0"}}>
                            By <b style={{color:"black"}} dangerouslySetInnerHTML={{ __html: authorDetail.authorName }}></b>
                            <p className="card__text" dangerouslySetInnerHTML={{ __html: authorDetail.authorDesc }}></p>
                        </div>
                    </div></div>)}</>
    )
}
const ListComment=({comments})=>{
    return(<>{comments.map(item=>(
    <div className="author-description-container"><div style={{backgroundColor: "#fff", border: "none",
        paddingLeft: "0",    alignItems: "baseline"}} className="author-descriptions">
    <span className="user-line">
        <img src="https://addisfortune.news/wp-content/themes/addis-fortune/images/defaultPhots/120.jpg" alt="" title="" style={{width: "40px"}}/>

        </span>
    <div className="card__details" style={{margin:"auto 0"}}>
        <b style={{color:"black"}} dangerouslySetInnerHTML={{ __html: item.fullname }}></b>
        
        <div style={{display: "flex",fontWeight: "bold",marginRight: "2rem"}}>
            <span style={{fontSize:'.9rem'}}>{item.created}</span>
                </div>
        <p style={{color: "rgb(24, 22, 22)",fontSize: "1.4rem"}} className="card__text" dangerouslySetInnerHTML={{ __html: item.message }}></p>
    </div>
</div></div>))}</>)
}
class AdComponent extends React.Component {
    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    
      render() {
        return (
            <ins className="adsbygoogle"
            style={{display:'block'}}
            data-ad-client="ca-pub-23452425"
            data-ad-slot={this.props.slot}
            data-ad-format="auto">
       </ins>
        );
      }
  }
export {AuthorDetail,ListComment,AdComponent}